import { render, staticRenderFns } from "./view_ProductUpsells.vue?vue&type=template&id=66e9c0a8&scoped=true"
import script from "./view_ProductUpsells.vue?vue&type=script&lang=js"
export * from "./view_ProductUpsells.vue?vue&type=script&lang=js"
import style0 from "./view_ProductUpsells.vue?vue&type=style&index=0&id=66e9c0a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e9c0a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VContainer,VProgressCircular})
